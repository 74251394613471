body {
  background-color: var(--success_color);
}
.cursor-pointer {
  cursor: pointer;
}
app-footer {
  display: flex;
  position: absolute;
  right: 0;
  bottom: -50px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--white_color);
}
::-webkit-scrollbar {
  width: 8px;
  background-color: var(--white_color);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--gray_color3);
}
.found-icon {
  font-size: 45px;
  color: var(--gray_color3);
}
.break-word {
  word-break: break-word;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

b,
strong {
  font-weight: 500;
}
.back-arrow {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray_color) !important;
  .material-symbols-outlined {
    position: relative;
    top: 2px;
    font-size: 15px;
    left: 5px;
  }
}
.bs-chevron {
  color: var(--primary_color);
}
.form-control:disabled {
  color: #9b9b9b;
}
.sticky-header {
  position: sticky;
  top: 10px;
  margin-bottom: 35px;
}
