h1 {
  color: var(--black_color);
}
h2 {
  color: var(--primary_color);
}
h3 {
  color: var(--black_color);
}
h4 {
  color: var(--secondary_color);
}
h5 {
  color: var(--black_color);
  line-height: 14px !important;
}
h6 {
  color: var(--black_color);
  font-size: 12px !important;
}
b {
  font-weight: 500;
}
