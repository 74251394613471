.mat-mdc-text-field-wrapper {
  background-color: var(--white_color);
  border: 2px solid var(--success_color);
  border-radius: 8px !important;
}
.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-size: 15px;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--white_color);
}
.mdc-line-ripple {
  display: none;
}
.mat-mdc-raised-button.mat-primary {
  border-radius: 25px;
  background-color: var(--primary_color) !important;
}
button.green-bg.mat-primary {
  border-radius: 25px;
  background-color: var(--green_color) !important;
}
button.green-bg.mat-primary {
  border-radius: 25px;
  background-color: var(--green_color) !important;
}
button.red-bg.mat-primary {
  border-radius: 25px;
  background-color: var(--red_color) !important;
}
.mat-mdc-raised-button.mat-accent {
  border-radius: 25px;
  background-color: var(--secondary_color) !important;
}
.mat-mdc-raised-button.mat-warn {
  border-radius: 25px;
  background-color: var(--secondary_color) !important;
}
.mat-mdc-unelevated-button.mat-primary {
  border-radius: 25px;
  background-color: var(--yellow_color) !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 45%);
}
.mat-mdc-outlined-button {
  border-radius: 25px !important;
  color: var(--gray_color) !important;
}
.mat-mdc-card {
  box-shadow: 0 6px 16px rgba(148, 159, 178, 44%);
}
.mat-simple-snackbar-action {
  color: var(--primary_color);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: var(--primary_font) !important;
  --mdc-snackbar-supporting-text-weight: 600;
  --mat-mdc-snack-bar-button-color: var(--primary_color);
  --mdc-snackbar-container-color: var(--white_color);
  --mdc-snackbar-supporting-text-color: var(--dark_gray_color);
}
.mat-mdc-snack-bar-label.mdc-snackbar__label {
  font-weight: 400 !important;
  &::first-letter {
    text-transform: uppercase;
  }
}

.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--primary_color) !important;
}
.mat-mdc-list-item .mdc-list-item__primary-text {
  font-size: 12px !important;
  line-height: 16px;
  font-weight: 400;
  vertical-align: text-top;
}
.mdc-list-item.mdc-list-item--with-one-line {
  height: 40px !important;
  cursor: pointer !important;
}
.mat-drawer-content {
  min-height: calc(100vh - 56px);
}
.mat-drawer-content {
  @media screen and (max-width: 767px) {
    margin-left: 0px !important;
  }
}
.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 55px;
  position: fixed;
}
.mat-drawer-container {
  padding-top: 55px;
}
.mdc-text-field--focused {
  .mdc-floating-label {
    color: var(--primary_color) !important;
  }
}
.mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 21px;
  font-size: 13px;
  line-height: 17px !important;
}
.mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

form
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
  .mat-mdc-form-field-infix {
  padding-top: 11px !important;
  padding-bottom: 5px !important;
  width: 100%;
}
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 14px !important;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 5px !important;
}
.mat-mdc-form-field-error {
  font-size: 11px;
  line-height: 12px;
}
form .mat-mdc-form-field {
  min-width: 100% !important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: rgba(25, 101, 186, 41%) !important;
}
.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: var(--primary_color) !important;
}
.mat-mdc-slide-toggle .mdc-form-field,
.mat-mdc-radio-button .mdc-form-field {
  font-size: 12.5px !important;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: var(--primary_color, --primary_color) !important;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: var(--primary_color, --primary_color) !important;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  width: 25px;
  height: 40px;
  padding: 8px 8px 8px 0px;
}
.mat-mdc-select-value {
  font-size: 14px;
}
.mat-calendar-body-selected {
  background-color: var(--primary_color) !important;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: var(--gray_color) !important;
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label {
  color: var(--white_color) !important;
}
.mdc-fab--mini {
  width: 30px !important;
  height: 30px !important;
  padding: 16px 15px 14px 15px !important;
  .material-symbols-outlined {
    font-size: 18px;
  }
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: var(--primary_color);
}
app-datepicker {
  .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
    top: 20px !important;
  }
}
.mat-expansion-indicator::after {
  color: var(--primary_color) !important;
}

.mat-expansion-panel {
  border-radius: 0px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  font-size: 11px;
  line-height: 32px;
  padding: 0 6px !important;
}
.mat-button-toggle-checked {
  background-color: var(--primary_color);
  color: var(--white_color) !important;
}
.mat-drawer {
  min-width: 215px;
  padding-top: 55px;
}
.mat-expansion-panel-body {
  padding: 0 !important;
}
.mat-expansion-panel-spacing {
  margin: 0 0 10px 0 !important;
}
.mat-accordion {
  .mat-accordion {
    .mat-expansion-panel-header {
      padding: 0 10px 0 15px;
      height: 38px;
      background: transparent !important;
    }
    .mat-expansion-panel-body {
      .mat-expansion-panel {
        padding-left: 32px;
        .mat-mdc-nav-list {
          padding: 0 10px 0 10px;
        }
      }
    }
    .mat-expansion-panel-header-title {
      font-size: 13px;
      font-weight: 400;
    }
    .mat-mdc-nav-list {
      padding: 0px 10px 5px 45px;
    }
    .mdc-list-item.mdc-list-item--with-one-line {
      min-width: 125px;
      padding: 5px 5px !important;
      border-radius: 5px;
    }
  }
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: inherit;
}

.filter_form {
  .mat-mdc-select-arrow {
    top: 5px;
  }
}

.mat-primary
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: var(--primary_color);
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--primary_color);
}
.mat-datepicker-toggle-active,
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--primary_color);
}

.icon-btn.mdc-button {
  min-width: 35px;
  padding: 10px;
}
.mat-mdc-option {
  font-size: 12px !important;
  min-height: 30px !important;
}
button.mat-mdc-paginator-navigation-next {
  padding: 0px !important;
  margin: 10px;
}
.mat-mdc-select-arrow {
  top: 5px;
}
.mat-mdc-paginator-container {
  .mat-mdc-select-arrow svg {
    top: -3px !important;
  }
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-focus-icon-color: var(--primary_color);
  --mdc-checkbox-selected-hover-icon-color: var(--primary_color);
  --mdc-checkbox-selected-icon-color: var(--primary_color);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary_color);
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: var(--neutral_color);
  --mdc-checkbox-selected-focus-icon-color: var(--success_color);
  --mdc-checkbox-selected-hover-icon-color: var(--success_color);
  --mdc-checkbox-selected-icon-color: var(--success_color);
  --mdc-checkbox-selected-pressed-icon-color: var(--success_color);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: var(--primary_color);
}
.mat-mdc-checkbox-disabled {
  cursor: default;
  opacity: 0.3;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: var(--gray_color);
}
.mat-sort-header-arrow {
  color: var(--tertiary_color);
}
.mat-mdc-select-panel {
  .mdc-list-item {
    padding-left: 5px;
    padding-right: 5px;
  }
  .mat-mdc-option .mat-pseudo-checkbox {
    margin-right: 5px;
  }
  .mat-pseudo-checkbox {
    width: 14px;
    height: 14px;
  }
  .filter_option span.mdc-list-item__primary-text {
    font-size: 11px !important;
    word-break: break-word !important;
    padding: 5px 0;
    line-height: 13px;
  }
  .mat-pseudo-checkbox-checked::after {
    width: 6px;
    height: 2px;
  }
}
.filter_form {
  mat-hint {
    font-size: 11px !important;
  }
}
