:root {
  --primary_color: #1965ba;
  --secondary_color: #ef6229;
  --tertiary_color: #cbcbcb;
  --success_color: #eaeaea;
  --neutral_color: #323232;
  --white_color: #ffffff;
  --lightgray_color: #ededed;
  --gray_color: #6b778c;
  --gray_color1: #eeeeee;
  --gray_color2: #505050;
  --gray_color3: #a0a0a0;
  --gray_color4: #efefef;
  --gray_color5: #7e7e7e;
  --black_color: #000000;
  --green_color: #198754;
  --red_color: #dc3545;
  --yellow_color: #fea30e;
}
