/* You can add global styles to this file, and also import other style files */
@import 'theme/variables';
@import 'theme/typography';
@import 'theme/colors_style';
@import 'theme/material_style';
@import 'theme/bootstrap_style';
@import 'theme/custom_style';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.bodyContainer {
  height: calc(100vh - 55px);
}
.bodyContainerDetail {
  height: calc(100vh - 100px);
  overflow: scroll;
}
.table-body {
  height: calc(100vh - 264px);
}
.table-container {
  height: calc(100vh - 315px) !important;
  padding-bottom: 0px !important;
  overflow: scroll;
}
.table-containerMis {
  height: calc(100vh - 320px) !important;
}
.mdc-data-table__cell {
  border: 1px solid var(--tertiary_color);
  text-align: center !important;
}
.mat-mdc-table .mdc-data-table__row {
  border-bottom: 1px solid var(--tertiary_color);
}
.mdc-data-table__header-cell {
  text-align: center !important;
}
.table-body-large {
  height: calc(100vh - 200px);
}
.table-container-large {
  height: calc(100vh - 258px);
}
.admin-body {
  height: calc(100vh - 135px);
}
